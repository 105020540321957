<template>
    <div class="page">
        <div class="page-header pd">
            <div>
                <sc-button icon="plus" @click="$router.push(`new`)" v-if="$user.isAtLeast(3)">Add {{
                        resource.name
                    }}
                </sc-button>
            </div>
            <sc-search :table="table" field="q"></sc-search>
        </div>

        <sc-table :table="table" v-if="table">

            <template #column-thumb="{row}">
                <div class="thumb" style="word-break:break-all;">
                    <img :src="getThumb(row)"/>
                </div>
            </template>

            <template #column-edit="{row}">
                <sc-table-cell :table="table" field="edit" :padded="false" :clickable="false">
                    <sc-table-button @click="edit(row)" icon="edit" v-if="canManage(row)">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-details="{row}">
                <sc-table-cell :table="table" field="details" :padded="false" :clickable="false">
                    <sc-table-button @click="playlistDetails=row" icon="eye">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)" v-if="canManage(row)">
                        Do you wish to delete the {{ resource.name }} "{{ row.attributes.name }}"?
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>

        </sc-table>

        <playlist-details :item="playlistDetails" @close="playlistDetails=undefined" v-if="playlistDetails"/>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";
import PlaylistDetails from "@/views/app/playlists/components/playlist-details";

export default {
  name: "all",
  components: {PlaylistDetails, ScSearch},

  data() {
    return {
      table: null,
      detailsID: undefined,
      playlistDetails: undefined
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `List of ${this.resource.plural}`,
      url: this.resource.api,
      sortBy: 'name',
      columns: {
        thumb: {
          sort: false,
          width: 300
        },
        name: {
          fill: true,
          sort: false,
          target: 'attributes.name'
        },
        description: {
          fill: true,
          sort: false,
          target: 'attributes.description'
        },
        updated_at: {
          sort: false,
          target: 'attributes.updated_at',
          type: 'date'
        },
        edit: {width: 70, sort: false, guard: !this.$user.isAtLeast(3)},
        details: {width: 70, sort: false, guard: !this.$user.isAtLeast(3)},
        delete: {width: 80, sort: false, guard: !this.$user.isAtLeast(1)}
      },
      hover: false,
    });
    this.table.fetch();
  },

  methods: {
    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.table.fetch();
          this.$notify.success(`${row.attributes.name} deleted`)
        })
    },

    edit(row) {
      this.$router.push(`${row.id}/edit`)
    },

    getThumb(row) {
      return `${process.env.VUE_APP_AWS_URL}${row.attributes.thumb}`;
    },

    canManage(row) {
      if (!row.hasOwnProperty('created_by')) {
        return true;
      } else {
        return this.$user.isAtLeast(3)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}

.thumb {
    width: 100%;
    height: 150px;
    max-height: 150px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5em;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}
</style>