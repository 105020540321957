<template>
    <sc-modal title="Playlist Details" @close="$emit('close')">
        <div class="details-row">
            <div class="details-key">Item</div>
            <div class="details-value">{{ item.attributes.name }}</div>
        </div>
        <div class="details-row">
            <div class="details-key">Created by</div>
            <div class="details-value" v-if="creator">{{ creator }}</div>
            <div class="details-value" v-else><i>Deleted User</i></div>
        </div>
        <div class="details-row">
            <div class="details-key">Created at</div>
            <div class="details-value">{{ item.attributes.created_at | toDatetime }}</div>
        </div>
    </sc-modal>
</template>

<script>
export default {
  name: "playlist-details",
  props: {
    item: {required: true, type: Object}
  },

  computed: {
    creator() {
      if (this.item && this.item.created_by) {
        return `${this.item.created_by.data.attributes.first_name} ${this.item.created_by.data.attributes.last_name} (${this.item.created_by.data.attributes.email})`
      }
    }
  },
}
</script>

<style scoped lang="scss">

.details-row {

    padding: .5em .75em;
    border: 1px solid #e2e2e2;

    &:nth-child(1n+2) {
        border-top: none;
    }

    div {
        display: inline-block;
    }

    i {
        font-style: italic;
    }

    .details-key {
        width: 140px;
    }
}

</style>