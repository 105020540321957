var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page-header pd"},[_c('div',[(_vm.$user.isAtLeast(3))?_c('sc-button',{attrs:{"icon":"plus"},on:{"click":function($event){return _vm.$router.push("new")}}},[_vm._v("Add "+_vm._s(_vm.resource.name)+" ")]):_vm._e()],1),_c('sc-search',{attrs:{"table":_vm.table,"field":"q"}})],1),(_vm.table)?_c('sc-table',{attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"column-thumb",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"thumb",staticStyle:{"word-break":"break-all"}},[_c('img',{attrs:{"src":_vm.getThumb(row)}})])]}},{key:"column-edit",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"edit","padded":false,"clickable":false}},[(_vm.canManage(row))?_c('sc-table-button',{attrs:{"icon":"edit"},on:{"click":function($event){return _vm.edit(row)}}}):_vm._e()],1)]}},{key:"column-details",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"details","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"eye"},on:{"click":function($event){_vm.playlistDetails=row}}})],1)]}},{key:"column-delete",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"delete","padded":false,"clickable":false}},[(_vm.canManage(row))?_c('sc-table-button-delete',{on:{"destroy":function($event){return _vm.destroy(row)}}},[_vm._v(" Do you wish to delete the "+_vm._s(_vm.resource.name)+" \""+_vm._s(row.attributes.name)+"\"? ")]):_vm._e()],1)]}}],null,false,2496727133)}):_vm._e(),(_vm.playlistDetails)?_c('playlist-details',{attrs:{"item":_vm.playlistDetails},on:{"close":function($event){_vm.playlistDetails=undefined}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }